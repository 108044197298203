var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-container',{staticClass:"padding-container detailed-view-container"},[_c('v-row',[_c('span',{staticClass:"back-to-review w-100",on:{"click":function($event){return _vm.$router.push({
            name: 'members-profile',
            params: {
              id: _vm.$route.params.id,
            },
          })}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v("Back to Overview ")],1),_c('h3',{staticClass:"questionnnaire-title pl-2"},[_vm._v(" Edmonton symptom assessment system (ESAS) ")])]),_c('v-row',{staticClass:"evolution"},[_c('v-col',{staticClass:"sub-title pa-3 pt-0 mb-11",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t("evolution"))+" ")]),_c('v-col',{staticClass:"label pt-0 mb-11 d-flex justify-end",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"secondary rounded-circle d-inline-block first-digram-label"}),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("current_report")))])]),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"secondary rounded-circle d-inline-block second-digram-label"}),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("previous_report")))])])]),_c('v-col',{staticClass:"pr-9 pl-9 graph",attrs:{"cols":"12"}},[_vm._l((10),function(line,i){return _c('hr',{key:(i + "-dashed-line"),style:(("" + (_vm.generateDashlinePosition(i)))),attrs:{"data-index":i + 1}})}),_vm._l((_vm.series),function(item,i){return _c('v-row',{key:i,staticClass:"graph-row"},[_c('v-col',{staticClass:"pl-5",attrs:{"cols":"3"}},[_c('div',{staticClass:"min-label"},[_vm._v(_vm._s(item.minText))])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"buffer-value":"50","value":item.current * 10,"color":_vm.$store.getters['auth/user'].theme_config
                  .progress_bar_primary_color,"height":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
return [_c('div',{staticClass:"value pr-2",style:(("width:" + value + "%"))},[_vm._v(" "+_vm._s(value / 10)+" ")])]}}],null,true)}),_c('v-progress-linear',{attrs:{"value":item.previous * 10,"color":_vm.$store.getters['auth/user'].theme_config
                  .progress_bar_secondary_color,"height":"12","aria-label":"false"}})],1),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"3"}},[_c('div',{staticClass:"max-label"},[_vm._v(_vm._s(item.maxText))])])],1)})],2)],1),_c('ESASQuestionnairePanels',{attrs:{"questionnaires":_vm.questionnaires,"questions":_vm.questionnaireQuestions}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }